import { render, staticRenderFns } from "./project-empty.vue?vue&type=template&id=266c60d0&scoped=true"
import script from "./project-empty.vue?vue&type=script&lang=js"
export * from "./project-empty.vue?vue&type=script&lang=js"
import style0 from "./project-empty.vue?vue&type=style&index=0&id=266c60d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266c60d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
